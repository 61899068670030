import React, { Component } from 'react';
import { Container } from '@mui/material';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    const { backgroundClass, children } = this.props;
      const containerClass = `main-background ${backgroundClass}`;

    return (
      <div>
        <Container className={containerClass}>
          {children}
        </Container>
      </div>
    );
  }
}