import React from 'react';
import { Typography, Container, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!location.state?.paymentSuccessful) {
      navigate('/payment');
    }
  }, [location, navigate]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Payment Successful</Typography>
      <Typography variant="body1" paragraph>
        Thank you for your payment. You can download your PDF using the link below.
      </Typography>
      <Button variant="contained" color="primary" href="/path-to-your-pdf.pdf" download>
        Download PDF
      </Button>
    </Container>
  );
};

export default SuccessPage;