import React, { useEffect, useState } from 'react';

const ComperitionList = () => {
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    fetch('/api/competition/active')
      .then(response => response.json())
      .then(data => {
        setCompetitions(data);
      })
      .catch(error => {
        console.error('Error fetching competitions data:', error);
      });
  }, []);

  return (
    <div>
      <h1>Available Competitions</h1>
      <ul>
        {competitions.map(competition => (
          <li key={competition.CompetitionGuid}>
            <h2>{competition.Title}</h2>
            <p>{competition.Description}</p>
            <p>Start Date: {new Date(competition.StartDate).toLocaleDateString()}</p>
            <p>End Date: {new Date(competition.EndDate).toLocaleDateString()}</p>
            {competition.PaymentRequired && <p>Cost: ${competition.Cost}</p>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComperitionList;