import React from 'react';
import { Link } from 'react-router-dom';
import {Button, Container, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import DisneyDistances from "./DisneyDistances";
import DisneyQueueTimes from "./DisneyQueueTimes";

const Competitions = () => {
  // Placeholder for competitions
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6">Competition Text</Typography>
          <p>Details about the competition...</p>
        </Grid>
        <Grid item xs={4}>
          <img src="https://via.placeholder.com/150" alt="Competition" />
        </Grid>
        <Grid item xs={4}>
            <Button component={Link} to="/comperition/1" variant="contained" color="primary">
              Enter Competition
            </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const MagicFinderPage = () => {
  return (
    <Container>
        <Grid container spacing={2}>
            <Grid size={4}>
                <DisneyDistances />
            </Grid>
            <Grid size="grow">
                <Competitions />
            </Grid>
            <Grid  size={12}>
                <DisneyQueueTimes/>
            </Grid>
        </Grid>
    </Container>
  );
};

export default MagicFinderPage;