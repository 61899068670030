import React, { useEffect, useState } from 'react';

const Sitemap = () => {
  const [books, setBooks] = useState([]);
  const baseUrl = 'https://www.weboverlord.com';

  useEffect(() => {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        setBooks(data);
      })
      .catch(error => {
        console.error('Error fetching books data:', error);
      });
  }, []);

  const selectedRoutes = [
    '/',
    '/books',
  ];

  const bookRoutes = books.map(book => `${baseUrl}/book/${book.Id}`);

  const pages = [...selectedRoutes.map(route => `${baseUrl}${route}`), ...bookRoutes];

  const sitemapXml = `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${pages.map(page => `
        <url>
          <loc>${page}</loc>
        </url>
      `).join('')}
    </urlset>
  `;

  return (
    <div>
      <pre>{sitemapXml}</pre>
    </div>
  );
};

export default Sitemap;