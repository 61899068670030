import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, List, ListItem, Divider, Button, Card, CardContent, Grid } from '@mui/material';

export class BooksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupedBooks: {},
      groupedBooksUniqueHashtags: {}
    };
  }

  componentDidMount() {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        const groupedBooks = this.groupBooksBySection(data);
        const groupedBooksUniqueHashtags = this.extractUniqueHashtags(groupedBooks);
        this.setState({ groupedBooks, groupedBooksUniqueHashtags });
      });
  }

  groupBooksBySection(books) {
    return books.reduce((groups, book) => {
      const section = book.Section || 'Uncategorized';
      if (!groups[section]) {
        groups[section] = [];
      }
      groups[section].push(book);
      return groups;
    }, {});
  }

  extractUniqueHashtags(groupedBooks) {
    const uniqueHashtags = {};
    for (const section in groupedBooks) {
      const hashtags = new Set();
      groupedBooks[section].forEach(book => {
        book.Hashtags.forEach(tag => hashtags.add(tag));
      });
      uniqueHashtags[section] = Array.from(hashtags).join(', ');
    }
    return uniqueHashtags;
  }

  render() {
    const { groupedBooks, groupedBooksUniqueHashtags } = this.state;

    return (
      <div>
        <Typography variant="h4" gutterBottom>Books List</Typography>
        {Object.keys(groupedBooks).map(section => (
          <div key={section}>
            <Typography variant="h5" gutterBottom>{section}</Typography>
            <Typography variant="body2" gutterBottom>{groupedBooksUniqueHashtags[section]}</Typography>
            <List>
              {groupedBooks[section].map((book, index) => (
                <ListItem key={book.Id}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                          <Typography variant="h6">
                            <RouterLink to={`/book/${book.Id}`} color="inherit">
                              {book.Title}
                            </RouterLink>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">{book.Description}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Button variant="contained" color="primary" href={book.saleUrl} target="_blank">
                            Buy on Amazon
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </List>
            <Divider />
          </div>
        ))}
      </div>
    );
  }
}