import React from 'react';
import { Fab, Typography, Breadcrumbs, Link } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Layout } from './Layout';
import './BookDetails.css';
import NavigationIcon from '@mui/icons-material/Navigation';

function BookDetails() {
  const { id } = useParams();
  const [book, setBook] = React.useState(null);

  React.useEffect(() => {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => {
        const book = data.find(b => b.Id === parseInt(id));
        setBook(book);
        if (book) {
          document.title = `${book.Title} - WebOverLord`;
        }
      });
  }, [id]);

  if (!book) {
    return <div>Loading...</div>;
  }

  return (
    <Layout backgroundClass={book.Background}>
      <div className="book-details">
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit">
            Home
          </Link>
          <Link component={RouterLink} to="/books" color="inherit">
            Books
          </Link>
          <Typography color="textPrimary">{book.Title}</Typography>
        </Breadcrumbs>
        <Typography variant="h1" gutterBottom>{book.Title}</Typography>
        <Typography variant="body1" paragraph>{book.Description}</Typography>
        <Typography variant="body2">Author: {book.Author}</Typography>
        <Typography variant="body2">Section: {book.Section}</Typography>
        <Fab variant="extended" size="medium" color="primary" href={book.saleUrl} target="_blank">
          <NavigationIcon sx={{ mr: 1 }} />
          Get on Amazon
        </Fab>
      </div>
    </Layout>
  );
}

export default BookDetails;