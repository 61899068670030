import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { NavMenu } from "./components/NavMenu";
import { lightTheme } from './theme';
import { ThemeProvider } from "@mui/material";

const App = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <ThemeProvider theme={lightTheme}>
      <main>
        {!isHomePage && <NavMenu />}
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </Layout>
      </main>
    </ThemeProvider>
  );
};

export default App;